import React from 'react'
import logo from '../../assets/images/logoHlavne.png'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import './emailSent.css'
const EmailSent = () => {
    return (
        <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <img className='emailSent_logo' src={logo} alt="taprofi Logo" />
                <Typography component="h6" variant="h6" align="center" margin='10px'>
                    Bež si skontrolovať SVOJ email
                </Typography>
            </Box>
        </Container >
    )
}

export default EmailSent