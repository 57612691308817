import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import logo from '../../assets/images/logoHeader.png';
import './EmailCollect.css';
import { useNavigate } from 'react-router-dom';

const EmailCollect = () => {
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const [remainingCount, setRemainingCount] = useState(null);
    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_BASE_URL_API });
    const navigate = useNavigate();

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    useEffect(() => {
        const fetchCount = async () => {
            try {
                const res = await axiosInstance.get("/email/count");
                if (res && res.data && res.data.count) {
                    const currentCount = res.data.count;
                    setRemainingCount(600 - currentCount);
                }
            } catch (err) {
                console.error(err);
            }
        };

        fetchCount();
    }, [axiosInstance]);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const email = formData.get('email');

        if (!emailRegex.test(email)) {
            setError("Prosím zadajte platnú emailovú adresu");
            return;
        }

        try {
            const res = await axiosInstance.post("/email/collect", { email });
            if (res && res.data) {
                setMsg(res.data.message || "Email collected successfully");
                navigate("/emailSent");
            } else {
                setError("Uppsss.... Niečo sa pokazilo");
            }
        } catch (err) {
            console.error(err);
            setError(err.response?.data?.message || "Uppsss.... Niečo sa pokazilo");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <img className='logo' src={logo} alt="taprofi Logo" />
                <Typography component="h6" variant="h4" align="center" margin='10px'>
                    PREDBEHNI SVOJICH KONKURENTOV!
                </Typography>

                <Typography component="body1" variant="body1" align="center">
                    Buď pri spustení stránky medzi prvými a získaj svoj JEDINEČNÝ zľavový KÓD na 4 mesiace ZADARMO!
                </Typography>

                <Typography component="body1" variant="body1" align="center">
                    Viac INFO o nás sa dozvieš v MAILI.
                </Typography>
                <Typography component="h6" variant="h4" align="center" margin="15px">
                    POZOR !
                </Typography>
                <Typography component="h6" variant="h6" align="center">
                    Ponuka platí iba pre prvých 5000!
                </Typography>
                <Typography component="h6" variant="h5" align="center" gutterBottom>
                    Zostáva už len {remainingCount !== null ? <span style={{ fontWeight: 'bold' }}>{remainingCount}</span> : "(načítavam...)"} zľavových kódov.
                </Typography>
                <Typography component="body1" variant="body1" align="center">
                    Kam ti pošleme ten TVOJ ?
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate md={{ mt: 1 }}>

                    <TextField
                        variant='outlined'
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        type="email"
                        error={Boolean(error)}
                        helperText={error}
                    />
                    <Button
                        disabled={isLoading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Odoslať
                    </Button>
                    {msg && <span style={{ display: 'flex', justifyContent: 'center' }}>{msg}</span>}
                </Box>
            </Box>
        </Container >
    );
};

export default EmailCollect;
