import { createTheme } from '@mui/material/styles';
const mainOrange = '#fd6b02'
export const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#fd6b02',
            contrastText: '#000000',
        },
        secondary: {
            main: '#ffa700',
        },
        error: {
            main: '#fd0000',
            light: '#fd3333',
            dark: '#b10000',
            contrastText: '##fd6b02',
        },
        text: {
            primary: '#fd6b02',
            secondary: '#fd6b02',
            disabled: '#fd6b02',
            hint: '#fd6b02',
            logo: '#fd6b02'
        },
        background: {
            default: '#282c34',
            paper: '#292323',
        },
    },
    components: {
        // Inputs
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${mainOrange}`,
                    },
                },
            }
        }
    }
});
