import React from 'react'
import EmailCollect from './emailCollect/EmailCollect'

const Home = () => {
    return (
        <>
            <EmailCollect />
        </>
    )
}

export default Home