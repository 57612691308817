import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './router/Home';
import ErrorPage from './router/ErrorPage';
import SharedLayout from './router/SharedLayout';
import EmailSent from './components/emailSent/EmailSent';
function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SharedLayout />}>
          <Route index path="/" element={<Home />} />
          <Route path="*" errorElement={<ErrorPage />} />
          <Route path="/emailSent" element={<EmailSent />} />
        </Route>
      </Routes>
    </BrowserRouter>


  );
}

export default App;
